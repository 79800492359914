import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
// import orders from "../../order.json";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllOrders } from "../../redux/odersSlice/ordersSlice";
import CircularIndeterminate from "../../layout/CircularIndeterminate";
import OrderPagination from "./OrderPagination";

const orderStatusCode = [
  { code: 1, description: "Yeni Sipariş", color: "#0000FF" },
  { code: 2, description: "Onaylandı", color: "#008000" },
  { code: 3, description: "Kargolandı", color: "#FFA500" },
  { code: 4, description: "Tamamlandı", color: "#006400" },
  { code: 5, description: "Hatalı", color: "#FF0000" },
  { code: 6, description: "Ön Sipariş", color: "#800080" },
  { code: 7, description: "Kargoya Hazır", color: "#FFA07A" },
  { code: 8, description: "Ödeme Alındı", color: "#FFD700" },
  { code: 9, description: "İade-İptal", color: "#8B0000" },
  { code: 10, description: "Onay Bekliyor", color: "#ADD8E6" },
  { code: 11, description: "İadesi Onaylanan", color: "#FFC0CB" },
  { code: 12, description: "Hazırlanıyor", color: "#90EE90" },
  { code: 13, description: "Tedarik Sürecinde", color: "#00008B" },
  { code: 14, description: "Tedarik Edilemedi", color: "#A9A9A9" },
  { code: 15, description: "Harici Depodan Gönderilecek", color: "#4B0082" },
];
const OrderTable = () => {
  const dispatch = useDispatch();
  const { orders, isLoading, error, pagination } = useSelector((state) => state.order);
  useEffect(() => {
    dispatch(getAllOrders(null));
    console.log(orders);
  }, [dispatch]);

  if (isLoading) {
    return <CircularIndeterminate />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!orders) {
    return null;
  }

  return (
    <div>
      <Box>
        <OrderPagination count={pagination} />
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Sipariş ID</TableCell>
              <TableCell>Sipariş No</TableCell>
              <TableCell>Tarih</TableCell>
              <TableCell>Firma</TableCell>
              <TableCell>Statu</TableCell>
              <TableCell>Spariş Detayı</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.orders.map((row) => {
              const col = orderStatusCode.find(
                (cl) => cl.code === parseInt(row.status)
              );
              return (
                <TableRow
                  key={row.id}
                  sx={{
                    backgroundColor: col.color,
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell sx={{ color: "white" }} component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    {row.order_number}
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>{row.datetime}</TableCell>
                  <TableCell sx={{ color: "white" }}>
                    {row.entegration.toUpperCase()}
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    {col.description}
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      to={`/orders/${row.order_number}`}
                    >
                      {" "}
                      Detaylar
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OrderTable;



import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BarcodeComponent from './BarcodeComponent';

export default function OrderInformation({order}) {
    if(!order)
      return <div>Veri Yok</div>
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell >Ürün Resmi</TableCell>
            <TableCell >Ürün İsmi</TableCell>
            <TableCell >Ürün İd</TableCell>
            <TableCell >Ürün Kodu</TableCell>
            <TableCell >Sipariş Miktarı</TableCell>
            <TableCell >Desi</TableCell>
            <TableCell >Barkod</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {order.order_product.map((row, i) => (
            <TableRow
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <img style={{width : "150px"}} src={row.picture} alt={row.name}/>
              </TableCell>
              <TableCell >{row.name}</TableCell>
              <TableCell >{row.product_id}</TableCell>
              <TableCell >{row.model}</TableCell>
              <TableCell >{row.quantity}</TableCell>
              <TableCell >{row.total_desi}</TableCell>
              <TableCell ><BarcodeComponent value={row.barcode}/></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getToken} from "../../utils/getToken"
const initialState = {
  users: [],
  me: [],
  formData: {
    email: "",
    password: "",
  },
  token: "",
  loading: false,
};
export const userMe = createAsyncThunk("me", async ( ) => {
  const token = getToken();
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVICE}/user/me`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.json();
});


//!login
export const loginUser = createAsyncThunk("login", async (requestData) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVICE}/auth/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const responseData = await response.json();
    document.cookie = "apiResponse=" + JSON.stringify(responseData.token);

    return responseData.token;
  } catch (error) {
    console.error("API isteği başarısız: ", error);
    throw error;
  }
});


//! update user
export const updateUser = createAsyncThunk("updateUser", async (requestData) => {
  try {
    const data = new FormData();

    data.append("email" , requestData.email);
    data.append("fullName", requestData.fullName);
    data.append("phoneNumber", requestData.phoneNumber);
    const response = await fetch(
      `${process.env.REACT_APP_API_SERVICE}/user/updateMe/${requestData.meId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${requestData.token}`,

        },
        body: JSON.stringify(requestData),
        // body : data,
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    return response.json();
  } catch (error) {
    console.error("API isteği başarısız: ", error.message);
    throw error;
  }
});

//! register

export const createUser = createAsyncThunk("createUser", async (requestData) => {
  try {
    const token = getToken();
    const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/auth/register`, {
      method : "POST",
      headers : {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body : JSON.stringify(requestData),
    })
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    return response.json();
  } catch (error) {
    console.error("API isteği başarısız: ", error.message);
    throw error;
  }
})

//! get all users
export const getAllUsers = createAsyncThunk("auth", async () => {
  // const response = await fetch('https://jsonplaceholder.typicode.com/posts')
  const token = getToken();
  const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/user/getAllUsers`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  const data = response.json();
  return data;
});

//! change status

export const changeStatus = createAsyncThunk("changeStatus" , async (_id) => {
  // try {
    const token = getToken();
    const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/user/changeStatus/${_id}`, {
      method : "GET",
      headers : {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    return response.json();
})

//! change Password

export const changePassword = createAsyncThunk("changePassword", async (data) => {
  const token = getToken();
  const formData = new FormData();
  formData.append('oldPass', data.oldPass);
  formData.append('newPass', data.newPass);
  const response = await fetch('http://localhost:3001/auth/changePassword', {
    method : "POST",
    headers : {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body : JSON.stringify(data),
  })
  const res = await response.json()
  if(!response.ok){
    alert(res.message);
    return;
  }

  alert(res.message);
  return;
})

export const deleteUser = createAsyncThunk("deleteUser", async (id) => {
  const token = getToken();
  
  const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/user/${id}` , {
    method : "DELETE",
    headers : {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  })
  console.log(response)
  if(!response.ok)
    throw new Error("Kullanıcı Silinemedi")
  return response.json();
})


const authSlice = createSlice({
  name: "user",
  initialState,
  //reducers fonksiyonları temsil eder setName setCountr useState de ki

  reducers : {
    addSassionStroge(state, action){
      localStorage.setItem("me", action.payload)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(userMe.fulfilled, (state, action) => {
      state.me = action.payload;
      try {
        localStorage.setItem("me", JSON.stringify(action.payload));
      } catch (error) {
        console.error("Could not save to localStorage", error);
      }
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.me = action.payload;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(changeStatus.fulfilled, (state, action) => {
      state.users = action.payload;
   
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      alert(action.payload);
    });
  },
});
export default authSlice.reducer;

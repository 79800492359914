import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { addNot } from "../../redux/productSlice/productSlice";
import { Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddNoteModal({pro, setOpenNodeModal, openNodeModal}) {
  const [not, setNot] = React.useState("");
  const dispatch = useDispatch();
  const sendNot = () => {
    // event.preventDefault();
    dispatch(addNot({productCode : pro.productCode, not}));
    handleClose();
  }

  const handleClose = () => {
    setOpenNodeModal(false);
  };

  return (
    <React.Fragment>

      <Dialog
        open={openNodeModal}
        onClose={handleClose}
        fullWidth
        TransitionComponent={Transition}
      >
        <DialogTitle>Ürün Notu</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ürün İle İlgili Notlar Alabilirsin
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="productNote"
            name="productNote"
            label="Not Ekle"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setNot(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Vazgeç</Button>
          <Button onClick={sendNot} type="button">Kaydet</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

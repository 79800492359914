import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from "react-redux";
import { getAllProduct } from '../../redux/productSlice/productSlice';
import { getAllOrders, setPageNum } from '../../redux/odersSlice/ordersSlice';


export default function OrderPagination({count}) {
  const dispatch = useDispatch();
   const page = useSelector(state => state.order.pagination)
 
  const handleChange = (event, value) => {
    event.preventDefault()
    dispatch(setPageNum(value)); // Tıklanan sayfanın değerini state'e kaydedin
    dispatch(getAllOrders(value));
  };

  React.useEffect(() => {
    return () => {
      dispatch(setPageNum(1));
    };
  }, []);


  return (
    <Stack spacing={2}>
      <Pagination
        page={page}// Tıklanan sayfa aktifse, selected özelliğini true olarak ayarlayın
        count={count}
        onChange={handleChange}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )}
      />
    </Stack>
  );
}
import { Button, Container, Typography } from "@mui/material";

import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DialogWindow from "../../component/Personal/DialogWindow";
import DataTable from "../../component/Personal/DataTable";

const Personal = () => {
  const [rows, setRows] = useState([]);
  const allUser = useSelector(state => state.user.users);

  useEffect(()=>{
    if(allUser){
      const updatedRows = allUser.map((user, index) => ({
        id: index + 1,
        _id : user._id,
        fullName: user.fullName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        role: user.role,
        isActive : user.isActive
      }));
      setRows(updatedRows);
    }
  },[allUser])

    return (
        <Container fixed>
        
        <div className="header mt-2" style={{display : "flex", justifyContent : "space-between"}}>
        <Typography variant="h3" component="h3">
          Kullanıcılar
        </Typography>
        <Typography>
          <DialogWindow/>
        </Typography>
      </div>
      <div>
            <DataTable rows={rows}/>

      </div>
        </Container>
    )
}

export default Personal;

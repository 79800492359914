import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useParams } from "react-router-dom";
// import orders from "../../order.json";
import { Container } from "@mui/material";
import GeneralInformation from "../../component/Orders/GeneralInformation";
import OrderInformation from "../../component/Orders/OrderInformation";
import { useDispatch, useSelector } from "react-redux";
import { getOrder } from "../../redux/odersSlice/ordersSlice";
import CircularIndeterminate from "../../layout/CircularIndeterminate";
import InvoiceInformation from "../../component/Orders/InvoiceInformation";

const OrderPage = () => {
  const { isLoading, error } = useSelector((state) => state.order);
  const order = useSelector((state) => state.order.singleOrder);

  console.log(order);
  const { order_number } = useParams();
  // const order = orders.find((ord) => ord.id === orderNo);
  const [value, setValue] = React.useState("1");
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    dispatch(getOrder(order_number));
  }, []);

  if (isLoading) {
    return <CircularIndeterminate />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!order) {
    return null;
  }
  return (
    <Container fixed>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Genel Bilgiler" value="1" />
              <Tab label="Sipariş Ürünleri" value="2" />
              <Tab label="Ek Bilgiler" value="3" />
              <Tab label="Fatura Bilgileri" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1">
            {order.orders && Array.isArray(order.orders) ? (
              <GeneralInformation order={order.orders[0]} />
            ) : (
              <div>Veri yok</div>
            )}
          </TabPanel>
          <TabPanel value="2">
            {order.orders && Array.isArray(order.orders) ? (
              <OrderInformation order={order.orders[0]} />
            ) : (
              <div>Veri yok</div>
            )}
          </TabPanel>
            <TabPanel value="3">Item Four</TabPanel>
          <TabPanel value="4">
          {order.orders && Array.isArray(order.orders) ? (
              <InvoiceInformation order={order.orders[0]} />
            ) : (
              <div>Veri yok</div>
            )}
          </TabPanel>
        </TabContext>
      </Box>
    </Container>
  );
};

export default OrderPage;

'use client';

import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Unstable_Grid2';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser, userMe } from '../../redux/AuthSlice/authSlice';
import { getToken } from '../../utils/getToken';
import { Box } from '@mui/material';
import ChangePassword from './ChangePassword';

export function AccountDetailsForm() {
  const [email , setEmail] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [error , setError] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(false)
  };

  const dispatch = useDispatch();
  const me = useSelector(states => states.user.me);

  const handleMeUpdate = (event) => {
    event.preventDefault();
    const token = getToken()
    const data = {email, fullName, phoneNumber, meId : me._id, token};
    dispatch(updateUser(data))
  }

  React.useEffect(()=>{
    dispatch(userMe())
  },[dispatch])
  return (
    <form
      onSubmit={handleMeUpdate}
    >
      <Card sx={{ borderRadius: 5, margin: 1 }}>
        <CardHeader subheader="Bilgilerini Güncelleyebilirsin" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid md={6} xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Ad Soyad</InputLabel>
                <OutlinedInput onChange={(e) => setFullName(e.target.value)} defaultValue={me.fullName} label="First name" name="firstName" />
              </FormControl>
            </Grid>
            <Grid md={6} xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Email address</InputLabel>
                <OutlinedInput onChange={(e) => setEmail(e.target.value)} defaultValue={me.email} label="Email address" name="email" />
              </FormControl>
            </Grid>
            <Grid md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Telefon Numarası</InputLabel>
                <OutlinedInput onChange={(e) => setPhoneNumber(e.target.value)} defaultValue={me.phoneNumber} label="Phone number" name="phone" type="tel" />
              </FormControl>
            </Grid>
            <Grid md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Entegra Email</InputLabel>
                <OutlinedInput disabled  defaultValue={me.entegraEmail} label="Entegra Email" name="entegraEmail" type="text" />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box sx={{display : "flex", justifyContent : "space-between", alignItems : "center "}}>
        <CardActions>
          <Button onClick={handleClickOpen} type='button' variant="contained">Şifremi Değiştir</Button>
        </CardActions>
        <CardActions>
          <Button type='submit' variant="contained">Kaydet</Button>
        </CardActions>
        </Box>
      </Card>
      <ChangePassword open={open} handleClose={handleClose} error={error} setError={setError}/>
    </form>
  );
}
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import DragAndDrop from './DragAndDrop';
import { addPicture } from '../../redux/productSlice/productSlice';
import { useDispatch } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddPictureModal({openPictureModal, setOpenPictureModal, pro}) {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [base64Files ,setBase64Files] = React.useState([])
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenPictureModal(false);
  };

  const addImage = () => {
    const formData = new FormData();
    const data = {}
    // Değerleri ekle
    formData.append("productCode", pro.productCode);
    base64Files.forEach((base64String, index) => {
      formData.append(`images_${index}`, base64String);
    });
    formData.append("name", pro.name)
  
    const jsonData = Object.fromEntries(formData.entries());

    // API çağrısı
    dispatch(addPicture(jsonData));
  
    // Temizlik ve kapatma
    setBase64Files([]);
    handleClose();
  }
  

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openPictureModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Bu Ürüne Resim Ekleyebilirsiniz"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
              <DragAndDrop base64Files={base64Files} setBase64Files={setBase64Files}/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Vazgeç</Button>
          <Button onClick={addImage}>Kaydet</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
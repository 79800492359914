import { Container, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import ProfilCard from "../../component/Account/ProfilCard";
import { AccountDetailsForm } from "../../component/Account/AccountDetailsForm";

const Account = () => {

  return (
    <Container fixed>
      <div className="header mt-2">
        <Typography variant="h3" component="h3">
          Hesabım
        </Typography>
      </div>
      <div className="mt-2">
        <Grid container spacing={2}>
          <Grid xs={12} md={4}>
            <ProfilCard/>
          </Grid>
          <Grid xs={12} md={8}>
            <AccountDetailsForm/>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default Account;

import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/tr'; // Türkçe dil desteği için

export default function ControlledComponent() {
  const [value, setValue] = React.useState(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          value={value}
          onChange={(newValue) => setValue(newValue)}
          format="DD/MM/YYYY" // Tarih formatını gün/ay/yıl olarak ayarlar
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

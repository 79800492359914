import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));


const GeneralInformation = ({order}) => {

    if (!order)
      return <div>Veri yok</div>; // Boş veri veya hatalı yapı için kontrol
    
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Item>
            <Typography
              sx={{ flex: "1 1 100%", paddingY: 1 }}
              variant="h6"
              id="tableTitle"
              component="div"
              fontWeight="bold"
              backgroundColor="#F7F7F7"
              textAlign="start"
              border="1px solid #F7F4F4"
            >
              &nbsp;Sipariş Bilgileri
            </Typography>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Sipariş ID
                    </TableCell>
                    <TableCell align="left">{order.id}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Sipariş No
                    </TableCell>
                    <TableCell align="left">{order.no}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Sipariş Tarihi
                    </TableCell>
                    <TableCell align="left">{order.datetime}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Platform
                    </TableCell>
                    <TableCell align="left">
                      <Box sx={{display : "flex"}}>
                        <Box>
                          {order.entegration === "trendyol" ? <img style={{width : "50px", height : "auto"}} src="/pazaryeriLogo/trendyolLogo.png"/> : order.entegration}
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Sipariş Veren Şirket
                    </TableCell>
                    <TableCell align="left">{order.company}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Ad Soyad
                    </TableCell>
                    <TableCell align="left">{order.firstname}&nbsp;{order.lastname}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item>xs=6 md=4</Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item>xs=6 md=4</Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item>xs=6 md=8</Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneralInformation;

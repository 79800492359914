const { Box } = require("@mui/material")

const InvoiceInformation = ({order}) => {
    return (
        <Box> 
            {order.invoice_url }
        </Box>
    )
}

export default InvoiceInformation;
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewPictureModal({
  openPreview,
  setOpenPreview,
  selectedProduct,
  setSelectedProduct,
}) {
  const [index, setIndex] = React.useState(0);
  const indexSize = (length, operator) => {
    if (index < length) {
      if (operator === "+" && index >= 0 && index !== length - 1)
        setIndex(index + 1);
      else if (operator === "-" && index >= 0 && index !== 0)
        setIndex(index - 1);
    }
  };

  const handleClose = () => {
    setOpenPreview(false);
    setSelectedProduct(null);
    setIndex(0);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openPreview}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={"sm"}
        fullWidth
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Ürün Resimleri"}</DialogTitle>
        <DialogContent>
          {selectedProduct ? (
            <div>
              <div>
                {index + 1} / {selectedProduct?.pictures?.length} adet resim
                mevcut
              </div>
              <div>
                <div>
                  <div>
                    <img
                      src={
                        selectedProduct
                          ? selectedProduct.pictures[index].picture
                          : null
                      }
                      style={{ width: "auto", height: 500 }}
                      className="d-block img-fluid rounded mx-auto"
                      alt="..."
                    />
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    onClick={() =>
                      indexSize(selectedProduct.pictures.length, "-")
                    }
                  >
                    <ArrowBackIosIcon style={{ color: "blue" }} />
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    onClick={() =>
                      indexSize(selectedProduct.pictures.length, "+")
                    }
                  >
                    <ArrowForwardIosIcon style={{ color: "blue" }} />
                  </button>
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          ) : null}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

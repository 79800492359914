
import { ResponsivePie } from '@nivo/pie'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const ChartPipe = () => {
  const  data = [
        {
          "id": "KADIN",
          "label": "Kadın",
          "value": 75,
          "color": "hsl(275, 70%, 50%)"
        },
        {
          "id": "ERKEK",
          "label": "Erkek",
          "value": 350,
          "color": "hsl(55, 70%, 50%)"
        }
      ]
    return(
        <ResponsivePie
        data={data}
        width = {320}
        margin={{ top: 10, right: 80, bottom: 80, left: 80 }}
        startAngle={-180}
        innerRadius={0.2}
        padAngle={0.7}
        cornerRadius={15}
        activeOuterRadiusOffset={26}
        colors={{ scheme: 'category10' }}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsDiagonalLength={15}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    2
                ]
            ]
        }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
     
     
    />
    )
}

export default ChartPipe;
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { createProduct } from "../../redux/productSlice/productSlice";
import DragAndDrop from "./DragAndDrop";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CkEditor from "./CkEditor";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function ProductDialogWindow() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [productCode, setProductCode] = useState("");
  const [productNane , setProductName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [brand, setBrand] = useState("");
  const [not, setNot] = useState("");
  const [status, setStatus] = useState(Number);
  const [tax, setTax] = useState(Number);
  const [currencyType, setCurrencyType] = useState("");
  const [group, setGroup] = useState(480);
  const [editorData, setEditorData] = useState(
    "<p>Hello from CKEditor&nbsp;5!</p>"
  );
  const [base64Files, setBase64Files] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //SetStatus
  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };
  //SetTax
  const handleChangeTax = (event) => {
    setTax(event.target.value);
  };
  //SetCurrencyType
  const handleChangeCurrencyType = (event) => {
    setCurrencyType(event.target.value);
  };

  const sendData =  () =>{
    const formData = new FormData();
    formData.append("productCode" , capitalizeFirstLetter(productCode));
    formData.append("name" , productNane);
    formData.append("status", status);
    formData.append("quantity", quantity);
    formData.append("kdv_id", tax);
    formData.append("currencyType", currencyType);
    formData.append("description", editorData);
    base64Files.forEach((base64String, index) => {
      formData.append(`images_${index}`, base64String);
    });
    formData.append("brand", brand);
    formData.append("not", not);
    formData.append("group" , group);

    const formJson = Object.fromEntries(formData.entries());
        dispatch(createProduct(formJson));
        setBase64Files([]);
        handleClose();
      }
  return (
    <React.Fragment>
      <Button
        color="success"
        startIcon={<AddIcon />}
        variant="outlined"
        onClick={handleClickOpen}
      >
        Yeni Ürün Ekle
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}  
      >
        <DialogTitle>Yeni Ürün Ekle</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bu menüden yeni ürün ekleyebilirsiniz
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="productCode"
            name="productCode"
            label="Ürün Kodu"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setProductCode(e.target.value)}
          />
          <TextField
            required
            margin="dense"
            id="productName"
            name="productName"
            label="Ürün İsmi"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setProductName(e.target.value)}
          />
          <FormControl sx={{ marginTop: 3 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Ürün Durumu</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              label="Ürün Durumu Seçiniz"
              onChange={handleChangeStatus}
            >
              <MenuItem value={1}>Aktif</MenuItem>
              <MenuItem value={0}>Pasif</MenuItem>
            </Select>
          </FormControl>
          <TextField
            required
            margin="dense"
            id="quantity"
            name="quantity"
            label="Stok"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e) => setQuantity(e.target.value)}
          />
          <TextField
            required
            margin="dense"
            id="group"
            name="group"
            label="Ürün Kategorıisi"
            type="text"
            value={group}
            fullWidth
            variant="standard"
          />
          <FormControl sx={{ marginTop: 3 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Ürün KDV</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tax}
              label="Ürün KDV Oranınını Seçiniz"
              onChange={handleChangeTax}
            >
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={18}>18</MenuItem>
              <MenuItem value={20}>20</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ marginTop: 3 }} fullWidth>
            <InputLabel id="selectCurrencyType">Döviz Tipi</InputLabel>
            <Select
              labelId="selectCurrencyType"
              id="CurrencyTypeSelect"
              value={currencyType}
              label="Ürün Döviz Tipini Seçiniz"
              onChange={handleChangeCurrencyType}
            >
              <MenuItem value={"TRL"}>TRL</MenuItem>
              <MenuItem value={"USD"}>USD</MenuItem>
              <MenuItem value={"EUR"}>EUR</MenuItem>
              <MenuItem value={"CHF"}>CHF</MenuItem>
              <MenuItem value={"SFR"}>SFR</MenuItem>
              <MenuItem value={"RUB"}>RUB</MenuItem>
              <MenuItem value={"GBP"}>GBP</MenuItem>
              <MenuItem value={"JPY"}>JPY</MenuItem>
            </Select>
          </FormControl>
          <TextField
            required
            margin="dense"
            id="brand"
            name="brand"
            label="Marka Adı"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setBrand(e.target.value)}
          />
          <TextField
            required
            margin="dense"
            id="not"
            name="not"
            label="Ürün Notu"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setNot(e.target.value)}
          />
          <div style={{ marginTop: 13 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Ürün Resmi Eklemek İçin Tıklayın
              </AccordionSummary>
              <AccordionDetails>
                <DragAndDrop
                  base64Files={base64Files}
                  setBase64Files={setBase64Files}
                />
              </AccordionDetails>
            </Accordion>
          </div>
          <div style={{ marginTop: 13 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Ürün Açıklaması Girmek İçin Tıklayın
              </AccordionSummary>
              <AccordionDetails>
                <CkEditor
                  editorData={editorData}
                  setEditorData={setEditorData}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="button" onClick={sendData}>Subscribe</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import React from 'react';
import Barcode from 'react-barcode';

const BarcodeComponent = ({ value }) => {
    return (
        <div>
            <Barcode value={value} />
        </div>
    );
};

export default BarcodeComponent;
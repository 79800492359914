import { Box, Grid, IconButton, ThemeProvider, Tooltip } from "@mui/material";
import FmdBadSharpIcon from "@mui/icons-material/FmdBadSharp";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import ModeEditOutlineSharpIcon from "@mui/icons-material/ModeEditOutlineSharp";
import { useDispatch } from "react-redux";
import { addNot } from "../../redux/productSlice/productSlice";
const NotArea = ({setOpenNodeModal, not, productCode }) => {
  const dispatch = useDispatch();
  return (

      <Box
        sx={{
          padding: 1,
          margin: 1,
          height: 50,
          borderRadius: 1,
          color: "0066CC",
          bgcolor: "#EBF5FF",
          "&:hover": {
            color: "white",
            bgcolor: "#007FFF",
          },
        }}
      >
        <Grid spacing={1} container>
          <Grid item xs={11} lg={11}>
            <FmdBadSharpIcon fontSize="large" sx={{ color: "tomato" }} /> {not}
          </Grid>
          <Grid item xs={1} lg={1}>
            <Tooltip  placement="top-start" title="Delete">
                <DeleteOutlineSharpIcon fontSize="small" sx={{cursor : "pointer", marginRight:0.5}} onClick={() => dispatch(addNot({productCode : productCode, not : null}))} />
            </Tooltip>  
            <Tooltip placement="top-end" title="Güncelle">
              <ModeEditOutlineSharpIcon fontSize="small"  sx={{cursor : "pointer"}} onClick={() => setOpenNodeModal(true)} />
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
 
  );
};

export default NotArea;

import { Container } from "@mui/material";
import BoxSx from "../../component/BoxSx";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { userMe } from "../../redux/AuthSlice/authSlice";
import { getToken } from "../../utils/getToken";

const Dashboard = () => {
  const dispatch = useDispatch();

  return (
    <Container fixed>
        <div style={{display: "flex"}}>
            <BoxSx hg={500} wd={1500} />
        </div>
    </Container>
  );
};

export default Dashboard;

"use client";
import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Unstable_Grid2";
import { useDispatch, useSelector } from "react-redux";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import {
  updateProduct,
  updateStock,
} from "../../redux/productSlice/productSlice";
import NotArea from "./NotArea";
import AddNoteModal from "./AddNoteModal";
import { Typography } from "@mui/material";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import LoadingButton from "@mui/lab/LoadingButton";

export function ProductDetailsForm({ pro, editorData }) {
  const [productName, setProductName] = React.useState("");
  const [barcode, setBarcode] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [group, setGroup] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [currencyType, setCurrencyType] = React.useState("");
  const [kdv_id, setKdv_id] = React.useState("");
  const [buyingPrice, setBuyingPrice] = React.useState("");
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openNodeModal, setOpenNodeModal] = React.useState(false);
  const isLoading = useSelector((state) => state.product.isLoading);

  const changeStatus = () => {
    if (pro.status === "1") {
      dispatch(updateProduct({ status: 0, productCode: pro.productCode }));
    } else {
      dispatch(updateProduct({ status: 1, productCode: pro.productCode }));
    }
  };

  const updateSt = () => {
    dispatch(updateStock(pro.productCode));
    if (isLoading) handleClose();
  };

  const toggleStatus = (detail) => {
    if (detail === "status") changeStatus();
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMeUpdate = (event) => {
    event.preventDefault();
    dispatch(
      updateProduct({
        productCode: pro.productCode,
        productName,
        barcode,
        brand,
        group,
        quantity,
        currencyType,
        kdv_id,
        buying_price: buyingPrice,
        description: editorData,
      })
    );
  };
  return (
    <form onSubmit={handleMeUpdate}>
      <Card sx={{ borderRadius: 5, margin: 1 }}>
        <CardHeader
          title="Ürün Detayları"
          action={
            <div>
              <Tooltip title="Ürün Ayarları">
                <IconButton
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  aria-label="settings"
                  onClick={handleOpen}
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                role="menu"
                aria-haspopup="true"
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => toggleStatus("status")}>
                  {pro.status === "1" ? "Ürünü Pasif Et" : "Ürününü Aktif Et"}
                </MenuItem>
                <MenuItem onClick={() => updateSt()}>
                  {isLoading ? (
                    <LoadingButton loading variant="outlined">
                      Submit
                    </LoadingButton>
                  ) : (
                    "Stok Güncelle"
                  )}
                </MenuItem>
              </Menu>
            </div>
          }
        />
        <Divider />
        {pro.not ? (
          <NotArea
            setOpenNodeModal={setOpenNodeModal}
            not={pro.not}
            productCode={pro.productCode}
          />
        ) : (
          <Typography
            sx={{
              paddingLeft: 2,
              color: "gray",
              cursor: "pointer",
              "&:hover": {
                color: "primary.dark",
              },
            }}
            onClick={() => setOpenNodeModal(true)}
          >
            <AddLocationIcon fontSize="small" /> Ürün Notu Bulunmamaktadır! Not
            Eklemek İçin Tıkla
          </Typography>
        )}
        <AddNoteModal
          setOpenNodeModal={setOpenNodeModal}
          openNodeModal={openNodeModal}
          pro={pro}
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid md={12} xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Ürün Adı</InputLabel>
                <OutlinedInput
                  disabled
                  onChange={(e) => setProductName(e.target.value)}
                  defaultValue={pro.name}
                  label="Ürün Adı"
                  name="productName"
                />
              </FormControl>
            </Grid>
            <Grid md={4} xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Ürün Kodu</InputLabel>
                <OutlinedInput
                  disabled
                  defaultValue={pro.productCode}
                  label="Ürün Kodu"
                  name="productCode"
                />
              </FormControl>
            </Grid>
            <Grid md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="statusLabel">Statüs</InputLabel>
                <OutlinedInput
                  defaultValue={pro.status === "1" ? "Aktif" : "Pasif"}
                  label="Status"
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Barkod</InputLabel>
                <OutlinedInput
                  defaultValue={pro.barcode}
                  onChange={(e) => setBarcode(e.target.value)}
                  label="Barkod"
                  name="barcode"
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Müşteri</InputLabel>
                <OutlinedInput
                  defaultValue={pro.brand}
                  onChange={(e) => setBrand(e.target.value)}
                  label="Müşteri"
                  name="brand"
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Grup</InputLabel>
                <OutlinedInput
                  defaultValue={pro.group}
                  onChange={(e) => setGroup(e.target.value)}
                  label="Grup"
                  name="group"
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Stok</InputLabel>
                <OutlinedInput
                  defaultValue={pro.quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  label="Stok"
                  name="quantity"
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Para Birimi</InputLabel>
                <OutlinedInput
                  defaultValue={pro.currencyType}
                  onChange={(e) => setCurrencyType(e.target.value)}
                  label="Para Birimi"
                  name="currencyType"
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>KDV</InputLabel>
                <OutlinedInput
                  defaultValue={pro.kdv_id}
                  onChange={(e) => setKdv_id(e.target.value)}
                  label="KDV"
                  name="kdv_id"
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>Alış Fiyatı</InputLabel>
                <OutlinedInput
                  defaultValue={pro.buying_price}
                  onChange={(e) => setBuyingPrice(e.target.value)}
                  label="Alış Fiyatı"
                  name="buying_price"
                  type="text"
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button type="submit" variant="contained">
            Kaydet
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

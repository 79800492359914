// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/bar
import { ResponsiveBar } from '@nivo/bar'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export default function MyBarChart(){
    const data = [
        {
          "country": "AD",
          "hot dog": 0,
          "hot dogColor": "hsl(356, 70%, 50%)",
          "burger": 167,
          "burgerColor": "hsl(293, 70%, 50%)",
          "sandwich": 196,
          "sandwichColor": "hsl(326, 70%, 50%)",
          "kebab": 16,
          "kebabColor": "hsl(92, 70%, 50%)",
          "fries": 88,
          "friesColor": "hsl(88, 70%, 50%)",
          "donut": 43,
          "donutColor": "hsl(114, 70%, 50%)"
        },
        {
          "country": "AE",
          "hot dog": 188,
          "hot dogColor": "hsl(354, 70%, 50%)",
          "burger": 168,
          "burgerColor": "hsl(286, 70%, 50%)",
          "sandwich": 47,
          "sandwichColor": "hsl(152, 70%, 50%)",
          "kebab": 107,
          "kebabColor": "hsl(216, 70%, 50%)",
          "fries": 73,
          "friesColor": "hsl(38, 70%, 50%)",
          "donut": 200,
          "donutColor": "hsl(9, 70%, 50%)"
        },
        {
          "country": "AF",
          "hot dog": 162,
          "hot dogColor": "hsl(221, 70%, 50%)",
          "burger": 57,
          "burgerColor": "hsl(315, 70%, 50%)",
          "sandwich": 35,
          "sandwichColor": "hsl(138, 70%, 50%)",
          "kebab": 150,
          "kebabColor": "hsl(271, 70%, 50%)",
          "fries": 185,
          "friesColor": "hsl(270, 70%, 50%)",
          "donut": 162,
          "donutColor": "hsl(61, 70%, 50%)"
        },
        {
          "country": "AG",
          "hot dog": 83,
          "hot dogColor": "hsl(161, 70%, 50%)",
          "burger": 103,
          "burgerColor": "hsl(240, 70%, 50%)",
          "sandwich": 96,
          "sandwichColor": "hsl(264, 70%, 50%)",
          "kebab": 70,
          "kebabColor": "hsl(14, 70%, 50%)",
          "fries": 152,
          "friesColor": "hsl(241, 70%, 50%)",
          "donut": 151,
          "donutColor": "hsl(282, 70%, 50%)"
        },
        {
          "country": "AI",
          "hot dog": 195,
          "hot dogColor": "hsl(75, 70%, 50%)",
          "burger": 200,
          "burgerColor": "hsl(259, 70%, 50%)",
          "sandwich": 116,
          "sandwichColor": "hsl(62, 70%, 50%)",
          "kebab": 29,
          "kebabColor": "hsl(64, 70%, 50%)",
          "fries": 93,
          "friesColor": "hsl(336, 70%, 50%)",
          "donut": 19,
          "donutColor": "hsl(190, 70%, 50%)"
        },
        {
          "country": "AL",
          "hot dog": 104,
          "hot dogColor": "hsl(68, 70%, 50%)",
          "burger": 147,
          "burgerColor": "hsl(347, 70%, 50%)",
          "sandwich": 129,
          "sandwichColor": "hsl(136, 70%, 50%)",
          "kebab": 196,
          "kebabColor": "hsl(50, 70%, 50%)",
          "fries": 10,
          "friesColor": "hsl(323, 70%, 50%)",
          "donut": 0,
          "donutColor": "hsl(232, 70%, 50%)"
        },
        {
          "country": "AM",
          "hot dog": 25,
          "hot dogColor": "hsl(347, 70%, 50%)",
          "burger": 62,
          "burgerColor": "hsl(29, 70%, 50%)",
          "sandwich": 146,
          "sandwichColor": "hsl(241, 70%, 50%)",
          "kebab": 38,
          "kebabColor": "hsl(217, 70%, 50%)",
          "fries": 62,
          "friesColor": "hsl(314, 70%, 50%)",
          "donut": 1,
          "donutColor": "hsl(8, 70%, 50%)"
        }
      ]
    return(
    <ResponsiveBar
        data={data}
        keys={[
            'hot dog',
            'burger',
            'sandwich',
            'kebab',
            'fries',
            'donut'
        ]}
        indexBy="country"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        groupMode="grouped"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: 'fries'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'sandwich'
                },
                id: 'lines'
            }
        ]}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'country',
            legendPosition: 'middle',
            legendOffset: 32,
            truncateTickAt: 0
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'food',
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        role = "application"
        ariaLabel = "Nivo bar chart demo"
    />
)}
import { Container } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../redux/CategoriesSlice/categoriesSlice";
import CircularIndeterminate from "../../layout/CircularIndeterminate";

const Categories = () => {
    const {categories, isLoading, error} = useSelector((state) => state.categori);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllCategories());
    },[])

    if (isLoading) {
        return <CircularIndeterminate/>;
      }
    
      if (error) {
        return <div>Error: {error}</div>;
      }
    
      if (!categories) {
        return null;
      }
    


    return (<Container fixed>
        {categories.map((it, i) => (
            <div key={i}>
             {i} ---{it.name}
            </div>
        ))}
    </Container>)
}

export default Categories;
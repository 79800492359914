import React, { useState } from "react";
import {
  Grid,
  Button,
  Typography,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.css";
import { useDispatch } from "react-redux";
import { loginUser, userMe } from "../../redux/AuthSlice/authSlice";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../layout/ErrorMessage";

const Login = ({setIsLogin}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null); // Durum hook'u tanımla

  const handleLogin = (event) => {
    event.preventDefault();
 
    const data = { email: email, password: password };
    dispatch(loginUser(data))
      .unwrap()
      .then(() => {
        navigate("/");
        dispatch(userMe());
        setIsLogin(true);
      })
      .catch((rejectedValueOrSerializedError) => {
        if(rejectedValueOrSerializedError.message === "user not available"){
          setError("Kullanıcı Mevcut Değil"); // Hata durumunda error state'ini ayarla
        }
        else if(rejectedValueOrSerializedError.message === "the password is incorrect"){
          setError("Hatalı Şifre Girdiniz"); // Hata durumunda error state'ini ayarla
        }
        else{
          setError("Bir Hata Oluştu");
        }
        // const loginDiv = document.querySelector("#loginDiv");
        // loginDiv.classList.add("animate__animated", "animate__backInRight");
        setTimeout(()=>{
          setError(null)
        },3000)
      });
  };
  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid
        item
        xs={12}
        sm={6}
        style={{
          backgroundImage:
            'url("https://images.pexels.com/photos/95916/pexels-photo-95916.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        item
        xs={12}
        sm={6}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <form  onSubmit={handleLogin} style={{ width: "100%", maxWidth: 400, padding: 3 }} noValidate>
          <Typography className="text-center mb-5" variant="h3" gutterBottom>
            KOOD MAX ENTEGRE
          </Typography>
          {error && <div className="errorAlert"> <ErrorMessage message={error}/></div>}
          <div className="form-floating mb-3">
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="form-control"
              id="emailInput"
              required
            />
            <label htmlFor="emailInput">Email</label>
          </div>
          <div className="form-floating mb-3">
            <input
            onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="form-control"
              id="passwordInput"
              required
            />
            <label htmlFor="passwordInput">Password</label>
          </div>
          <Button type="submit" fullWidth variant="contained" color="primary">
            Sign In
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default Login;

import React,{useState}from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Grid, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { changePassword } from "../../redux/AuthSlice/authSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChangePassword({ open, handleClose, error, setError }) {
    const dispatch = useDispatch();
    const [newPass, setNewPass] = useState('');
    const [oldPass, setOldPass] = useState('');
    const [checkPass, setCheckPass] = useState('');

    const checkPassword = (newPass, checkNewPass) => {
        if(newPass === checkNewPass) 
            return true
        else
         return false
    }

    const handleReset = () => {
        setNewPass('');
        setOldPass('');
        setCheckPass('');
    };
    
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps = {{
            component : "form",
            onSubmit : (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());       
                if(checkPassword(formJson.newPass, formJson.checkPass))
                    dispatch(changePassword({newPass : formJson.newPass, oldPass : formJson.oldPass}))
                else{
                    setError(true)
                }
            }
          }}
      >
        <DialogTitle>{"Şifreni Değiştir"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid sx={{marginTop : 1}} spacing={2}>
               <Grid item sx={{marginTop : 1}} lg={12}>
                <TextField
                  id="outlined-password-input"
                  label="Eski Parola"
                  name="oldPass"
                  type="password"
                  fullWidth
                  autoComplete="current-password"
                  value={oldPass}
                  onChange={(e) => setOldPass(e.target.value)}

                />
              </Grid>
              <Grid item sx={{marginTop : 1}} lg={12}>
                <TextField
                  id="outlined-password-input"
                  label="Yeni Parola"
                  name="newPass"
                  type="password"
                  fullWidth
                  error={error}
                  value={newPass}
                  onChange={(e) => setNewPass(e.target.value)}
                  helperText={ error ? " Şifreler Aynı Olmalıdır" : null }
                  autoComplete="current-password"
                />
              </Grid>
              <Grid item sx={{marginTop : 1}} lg={12}>
                <TextField
                  id="outlined-password-input"
                  label="Yeni Parola"
                  name="checkPass"
                  type="password"
                  error={error}
                  fullWidth
                  autoComplete="current-password"
                  value={checkPass}
                  onChange={(e) => setCheckPass(e.target.value)}

                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => {handleClose(); handleReset()}}>Vazgeç</Button>
          <Button type="submit">Kaydet</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

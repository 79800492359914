import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CkEditor = ({ editorData, setEditorData }) => {
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  useEffect(() => {
    return() => {
      setEditorData("")
    }
  }, [])

  return (
    <div style={{ marginBottom: 30 }}>
        
       <CKEditor 
        editor={ClassicEditor}
        data={editorData}
        onChange={handleEditorChange}
      /> 
    </div>
  );
};

export default CkEditor;

import { configureStore } from '@reduxjs/toolkit'
import authSlice from './AuthSlice/authSlice'
import productSlice from './productSlice/productSlice'
import paginationSlice from './PaginationSlice/paginationSlice'
import ordersSlice from './odersSlice/ordersSlice'
import categoriesSlice from './CategoriesSlice/categoriesSlice'
import aiSlice from './AiSlice/aiSlice'

export default configureStore({
  reducer: {
    user : authSlice,
    product : productSlice,
    pagination : paginationSlice,
    order : ordersSlice,
    categori : categoriesSlice,
    ai : aiSlice
  }
})
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProduct } from "../../redux/productSlice/productSlice";
import CircularIndeterminate from "../../layout/CircularIndeterminate";
import { Checkbox } from "@mui/material";
import PreviewPictureModal from "./PreviewPictureModal";
import { Link } from "react-router-dom";
import { CiImageOff } from "react-icons/ci";
import { getAllCategories } from "../../redux/CategoriesSlice/categoriesSlice"


const ProductTable = () => {
  const dispatch = useDispatch();
  const { product, isLoading, error } = useSelector((state) => state.product);
  const { categories } = useSelector((state) => state.categori);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openPreview ,setOpenPreview] = useState(false)
  const label = { inputProps: { "aria-label": "Checkbox demo" } };


  useEffect(() => {
    dispatch(getAllProduct(1));
    dispatch(getAllCategories());
    // dispatch(getAllProductEntegra(1));
  }, [dispatch]);

  if (isLoading) {
    return <CircularIndeterminate />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!product) {
    return null;
  }
  return (
    <div style={{ marginBottom: 100 }}>
      <table className="table  table-hover table-bordered mt-2 border-radius">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Ürün Resmi</th>
            <th scope="col">Stok Kodu</th>
            <th scope="col">Ürün Adı</th>
            <th scope="col">Stok</th>
            <th style={{ fontSize: 10 }} scope="col">
              Stok Alarm Adeti
            </th>
            <th scope="col">Alış Fİyatı</th>
            <th scope="col">Desi</th>
            <th scope="col">Tedarikçi</th>
            <th scope="col">Ürün Detayı</th>
            <th scope="col">Not</th>
          </tr>
        </thead>
        <tbody>
          {product?.productList?.map((item, i) => (
            <tr key={i}>
              <th scope="row">{item.id}</th>
              <td>
                {item.pictures?.length !== 0 ? 
                <button
                  className="btn btn-link"
                  onClick={() => {setOpenPreview(true); setSelectedProduct(item)}}
                >
                  <img
                    style={{ cursor: "pointer", width:  "auto", height: 75 }}
                    src={
                      
                         item.pictures[0].picture
                         
                    }
                    alt=""
                  />
                </button>
                :  <div><CiImageOff size={70}/>
                </div>}
              </td>
              <td>{item.productCode}</td>
              <td>
                <div>{item.name}</div>
                <hr />
                <div style={{ fontSize: 9 }}>
                  {categories.map((categori, i) => (
                    item.group === categori.id ? <div key={i}>{categori.name_tree}</div> : null
                  ))}
                </div>
              </td>
              <td>{item.quantity}</td>
              <td>{item.critical_stock}</td>
              <td>{item.buying_price}</td>
              <td>{item.desi}</td>
              <td>{item.supplier}</td>
              <td>
                <Link to={`/product/${item.productCode}`}>Detaylar</Link>
              </td>
              <td>
                <div>
                  <Checkbox checked={item.not ? true : false} {...label} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PreviewPictureModal openPreview={openPreview} setOpenPreview={setOpenPreview} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct}/>
    </div>
  );
};

export default ProductTable;

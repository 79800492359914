import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getToken} from "../../utils/getToken"
const initialState = {
  product: [],
  singlePro : [],
  isLoading: true,
  error: null,
  isQuery : false,
};
//! getAllProduct

export const getAllProduct = createAsyncThunk("getAllProduct" , async (pageNumber) => {
  // try {
    const token = getToken();
    const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/product/myDataProduct?page=${pageNumber}`, {
      method : "GET",
      headers : {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    return response.json();
})

//! get all product Entegra
export const getAllProductEntegra = createAsyncThunk("getAllProductEntegra", async (pageNumber) => {
    // https://apiv2.entegrabilisim.com/product/page=1/
    const response = await fetch(`https://apiv2.entegrabilisim.com/product/page=${pageNumber}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization : 'JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE2MTUzODcyLCJqdGkiOiI3NjFiN2I0YzY5Mjk0YjRiYTViMzRkMGQ1MTE5Y2ZmZCIsInVzZXJfaWQiOjM1fQ.nQvPsNWhTbBlKVpNCJkNVRydHWctQB1S84wFOlmMuXI',
      },
  })

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }

  return response.json();

})

//! single product
export const singleProduct = createAsyncThunk("singleProduct", async (productCode) => {
  const token = getToken();
  const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/product/${productCode}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
})

if (!response.ok) {
  const errorData = await response.json();
  console.log("hatalısın sen hatalı")
  throw new Error(errorData.error);
}

return response.json();
})

//! addNot

export const addNot = createAsyncThunk("addNot", async (data) => {
  const token = getToken();

  const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/product/addNot`, {
    method : "POST",
    headers : {
      "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    },
    body : JSON.stringify(data)
  })
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }

  return response.json();


})

//! search area
export const searcName = createAsyncThunk("searcName" , async (value) => {    
    const token = getToken();
    // const response = await fetch(`${process.env.REACT_APP_AUTH_SERVICE}/product/page/${pageNumber}`, {
    const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/product/myDataProduct?${value.query}=${value.data}`, {
      method : "GET",
      headers : {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    return response.json();
})

//! create Product
export const createProduct = createAsyncThunk("createProduct", async (data) => {

  const token = getToken();
  const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/product/createProduct`, {
    method : "POST",
    headers : {
      'Content-Type': 'application/json', // Content-Type başlığı
      Authorization: `Bearer ${token}`,
    },
    body : JSON.stringify(data)
  })
  const res = await response.json();

  if(!response.ok){
    throw new Error(res.error);
  }

  if(res.error){
    throw new Error(res.error);
  }
    return res;
    
})

//! add Picture
export const addPicture = createAsyncThunk("addPicture", async (file) => {
  try {


   const token = getToken();
   console.log(file)

   if(!file)
    return ("Bir sorun oluştu")
   
    const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/product/addPicture`, {
      method: 'POST',
      headers : {
         'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`,
      },
      body:JSON.stringify(file)
    });
    const data = await response.json();
    console.log(data); // İşlem sonucunu kontrol etmek için gelen veriyi konsola yazdır
    return data;
  } catch (error) {
    console.error('Hata:', error);
  }
})

//! update

export const updateProduct = createAsyncThunk('updateProduct', async (rawData) =>{
  try {
    console.log("data " ,rawData);

    function kopyalaVeFiltrele(kaynak) {
      let hedef = {};
      Object.keys(kaynak).forEach(anahtar => {
          if (kaynak[anahtar] !== null && typeof kaynak[anahtar] === "object") {
              hedef[anahtar] = kopyalaVeFiltrele(kaynak[anahtar]);
          } else if (kaynak[anahtar] !== "") {
              hedef[anahtar] = kaynak[anahtar];
          }
      });
      return hedef;
  }
  
  const data = kopyalaVeFiltrele(rawData);
  console.log(data);

 
   const response = await fetchRequrest(`updateProduct?productCode=${data.productCode}`, 'PUT', data);

   if(!response.message)
    throw new Error(response.error);

    console.log("entegra data ", response.data);
    alert("Ürün Güncellendi")
   return response.data;
    
  } catch (error) {
    return error;
  }
})

export const updateStock = createAsyncThunk("updateStock", async(productCode) => {
  console.log("productCode" , productCode)
  const token = getToken();
  const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/product/updateStock/${productCode}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
})

if (!response.ok) {
  const errorData = await response.json();
  console.log("hatalısın sen hatalı")
  throw new Error(errorData.error);
}

return response.json();
})
 
const fetchRequrest = async (path, method, body) => {
  try {
    const token = getToken();
    const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/product/${path}`, {
      method, 
      headers : {
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`,
      },
      body : JSON.stringify(body)
    })
    const data = await response.json();
    if(!response.ok)
      throw new Error(data)

    return ({message : true, data});
  } catch (error) {
    return ({message : false, error});
  }
} 

const productSlice = createSlice({
  name: "product",
  initialState,
  //reducers fonksiyonları temsil eder setName setCountr useState de ki
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(getAllProduct.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getAllProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.product = action.payload;
    })
    .addCase(getAllProduct.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(getAllProductEntegra.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getAllProductEntegra.fulfilled, (state, action) => {
      state.isLoading = false;
      state.product = action.payload;
    })
    .addCase(getAllProductEntegra.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(searcName.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(searcName.fulfilled, (state , action ) => {
      state.isLoading = false;
      state.product = action.payload;
    })
    .addCase(searcName.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.isQuery = action.payload.isQuery
    })
    .addCase(createProduct.pending , state => {
      state.isLoading = true
    })
    .addCase(createProduct.fulfilled, (state, action) => {
      state.isLoading = false
      state.product = action.payload
      alert('Ürün Eklendi');
    })
    .addCase(createProduct.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      alert('Hata : ' + action.error.message);
    })
    .addCase(addPicture.fulfilled, (state, action) => {
      alert(action.payload.message)
    })
    .addCase(addPicture.rejected, (state, action) => {
      alert(action.payload.message)
    })
    .addCase(addNot.rejected, (state, action) => {
      alert(action.payload.message)
    })
    .addCase(singleProduct.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(singleProduct.fulfilled, (state, action) => {
      state.isLoading = false
      state.singlePro = action.payload;
    })
    .addCase(singleProduct.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      alert('Hata : ' + action.error.message);
    })
    .addCase(updateStock.pending, state => {
      state.isLoading = true;
    })
    .addCase(updateStock.fulfilled, (state, action) => {
      if(action.payload.message === "Stok Güncel"){
        state.isLoading = false;
        state.singlePro = action.payload.product;
        alert("Stok Güncel")
      }
      else{
      state.isLoading = false;
        state.singlePro = action.payload;
      }
    })
    .addCase(updateStock.rejected , (state, action) => {
      state.error = action.payload;
      state.isLoading = true
    })
  },
});

export default productSlice.reducer;

import * as React from 'react';
import { Box, ThemeProvider, Typography } from '@mui/material';
import ChartPipe from './ChartPipe';
import MyBarChart from './MyBarChart';

export default function BoxSx({wd, hg}) {

  return (
    <ThemeProvider
      theme={{
        palette: {
          primary: {
            main: '#007FFF',
            dark: '#0066CC',
          },
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: hg,
          margin : 1,
          borderRadius: 1,
          bgcolor: 'primary.main',
          '&:hover': {
            bgcolor: 'primary.dark',
          },
        }}
      >
      <MyBarChart/>
      </Box>
    </ThemeProvider>
  );
}
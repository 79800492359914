import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from "react-redux";
import { getAllProduct } from '../../redux/productSlice/productSlice';
import { setPageNumber } from '../../redux/PaginationSlice/paginationSlice';

export default function Paginations({count}) {
  const dispatch = useDispatch();
   const page = useSelector(state => state.pagination.page)
 
  const handleChange = (event, value) => {
    event.preventDefault()
    dispatch(setPageNumber(value)); // Tıklanan sayfanın değerini state'e kaydedin
    dispatch(getAllProduct(value));
  };

  React.useEffect(() => {
    return () => {
      dispatch(setPageNumber(1));
    };
  }, []);


  return (
    <Stack spacing={2}>
      <Pagination
        page={page}// Tıklanan sayfa aktifse, selected özelliğini true olarak ayarlayın
        count={Math.ceil(count/100)}
        onChange={handleChange}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )}
      />
    </Stack>
  );
}
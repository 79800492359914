import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getToken} from "../../utils/getToken"

const initialState = {
    orders : [],
    isLoading : true,
    error : null,
    singleOrder : [],
    pagination : null,
    otherPageNum : null
}

export const getAllOrders = createAsyncThunk("getAllOrders", async () => {
    const token = getToken();
    let response;


        response = await fetch(`${process.env.REACT_APP_API_SERVICE}/order`, {
            method : "GET",
            headers : {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        })
    
        
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
      return response.json();
})

export const getPageOrders = createAsyncThunk('getPageOrders', async (pageNum) => {
    const token = getToken();

   const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/order?pagenum=${pageNum}`, {
        method : "GET",
        headers : {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        }
    })
    
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
    }
    return response.json();
})


export const getOrder = createAsyncThunk ('getOrder' , async (order_number) => {
    const token = getToken();

    const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/order/${order_number}`, {
        method : "GET",
        headers : {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        }
    })

    if (!response.ok) {
        const errorData = await response.json();
        console.log(errorData)
        throw new Error(errorData.message);
      }
      return response.json();
})


const ordersSlice = createSlice({
    name : "order",
    initialState,
    reducers : {
        setPageNum(state ,action) {
            state.pagination = action.payload;
        },
        setOtherPageNum(state ,action) {
            state.otherPageNum = action.payload;
        }
    },
    extraReducers : (builder) => {
        builder
        .addCase(getAllOrders.pending , (state) => {
            state.isLoading = true;
        })
        .addCase(getAllOrders.fulfilled, (state, action) => {
            state.isLoading = false;
            state.orders = action.payload;
            state.pagination = Math.ceil(action.payload.totalOrder / 100);
            state.otherPageNum = null;
        })
        .addCase(getAllOrders.rejected , (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        })
        //! get Page Order
        .addCase(getPageOrders.pending , (state) => {
            state.isLoading = true;
        })
        .addCase(getPageOrders.fulfilled, (state, action) => {
            state.isLoading = false;
            state.orders = action.payload;
        })
        .addCase(getPageOrders.rejected , (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        })
        // singleOrder
        .addCase(getOrder.pending , (state) => {
            state.isLoading = true;
        })
        .addCase(getOrder.fulfilled, (state, action) => {
            state.isLoading = false;
            state.singleOrder = action.payload;
        })
        .addCase(getOrder.rejected , (state, action) => {
            state.isLoading = false;
            // state.error = action.error.message;
            console.log("action payload",action.error.message);
        })
    }
})

export const {setPageNum, setOtherPageNum} = ordersSlice.actions;
export default ordersSlice.reducer;
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { createUser } from "../../redux/AuthSlice/authSlice";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.css";
import ErrorMessage from "../../layout/ErrorMessage";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogWindow() {
    const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [role, setRole] = React.useState("");
  const [tempPass, setTempPass] = React.useState("");
  const [error, setError] = React.useState(null)
  
  
  const handleClickOpen = () => {
    setOpen(true);
    generateTemporaryPassword();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const generateTemporaryPassword = () => {
    const length = 5;
    const charset = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"; // Karakter seti: sadece rakamlar
    let password = "";
    
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    
    setTempPass(password);
  }

  React.useEffect(()=> {
    generateTemporaryPassword();
  },[])

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Yeni Kullanıcı Ekle
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            formData.append("role" , role);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            dispatch(createUser(formJson))
            .unwrap()
            .then((data) => {

              handleClose();
            }
            )
            .catch((error) => {
              setError(error.message)

              setTimeout(()=>{
                setError(null)
              },15000)
            })
           
          },
        }}
      >
     {error && <div className="errorAlert"> <ErrorMessage message={error}/></div>}

        <DialogTitle>Yeni Kullanıcı Ekle</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bu menüden yeni kullanıcı ekleyebilirsin
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="fullName"
            name="fullName"
            label="İsim Soyisim"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Adres"
            type="email"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="phoneNumber"
            name="phoneNumber"
            label="Telefon Numarası"
            type="phone"
            fullWidth
            variant="standard"
          />
            <TextField
            autoFocus
            required
            margin="dense"
            id="password"
            name="password"
            label="Geçici Şifre"
            type="text"
            value={tempPass}
            fullWidth
            variant="standard"
          />
          <FormControl sx={{ marginTop: 3 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Yetki Seçin</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={role}
              label="Yetki Seçin"
              onChange={handleChange}
            >
              <MenuItem value={"admin"}>Admin</MenuItem>
              <MenuItem value={"user"}>User</MenuItem>
            </Select>
          </FormControl>
          <TextField
            autoFocus
            required
            margin="dense"
            id="entegraEmail"
            name="entegraEmail"
            label="Entegra Email"
            type="text"
            fullWidth
            variant="standard"
          />
                    <TextField
            autoFocus
            required
            margin="dense"
            id="entegraPass"
            name="entegraPassword"
            label="Entegra Password"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Subscribe</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

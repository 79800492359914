import { Box, Container } from "@mui/material";
import MessageSearch from "../../component/Message/MessageSearch";

const Message = () => {
    return (
        <Container fixed>
                <h3 style={{ textDecoration: "underline" }}>Mesajlar</h3>
            <Box>
                <MessageSearch/>
            </Box>
        </Container>
    )
}

export default Message;
import {createSlice } from "@reduxjs/toolkit"

const initialState = {
    page : 1,
}

const paginationSlice = createSlice({
    name : 'pagination',
    initialState,
    reducers : {
        setPageNumber(state ,action) {
            state.page = action.payload;
        }
    }
})

export const {setPageNumber} = paginationSlice.actions;
export default paginationSlice.reducer;
import OrderSearch from "../../component/Orders/OrderSearch";
import OrderTable from "../../component/Orders/OrderTable";

const { Container } = require("@mui/material")

const Orders = () => {
    return (
        <Container fixed>
            <OrderSearch/>
              Siparişler
            <OrderTable/>
        </Container>
    )
}

export default Orders;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken } from "../../utils/getToken";

const initialState = {
    categories : [],
    isLoading : true,
    error : null,
}

export const getAllCategories = createAsyncThunk("getAllCategories", async () => {
    const token = getToken();

    const response = await fetch(`${process.env.REACT_APP_API_SERVICE}/categori`, {
        method : "GET",
        headers : {
            "Content-Type":"application/json",
            Authorization : `Bearer ${token}`
        }
    })
    if(!response.ok){
        const errorData = await response.json();
        throw new Error(errorData.error);
    }
    return response.json();
})

const categoriesSlice = createSlice({
    name : "categori",
    initialState,
    reducers : {},
    extraReducers : (builder) => {
        builder
        .addCase(getAllCategories.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(getAllCategories.fulfilled, (state, action) => {
            state.categories = action.payload.categories;
            state.isLoading = false;
        })
        .addCase(getAllCategories.rejected, (state, action) =>{
            state.isLoading = false;
            state.error = action.payload;
        } )
    }
})

export default categoriesSlice.reducer;
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Avatar, Stack } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import { useSelector } from "react-redux";


export default function ProfilCard() {
  const me = useSelector(state => state.user.me)

  return (
    <Card sx={{ maxWidth: 400, borderRadius: 5, margin: 1 }}>
      <CardMedia alt="green iguana" height="200">
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{marginTop : 3}}
        >
          {me && me.fullName ? <Avatar sx={{ bgcolor: deepOrange[500], width: 70, height: 70 }}>
            {me.fullName[0].toUpperCase()}
          </Avatar> : <Avatar sx={{ bgcolor: deepOrange[500], width: 70, height: 70 }}>
            X
          </Avatar>}
          
        </Stack>
      </CardMedia>
      <CardContent sx={{textAlign : "center"}}>
        <Typography gutterBottom variant="h5" component="div">
         { me.fullName }
        </Typography>
        <Typography variant="body2" color="text.secondary" >
        
                {me.email}
                <br/>
                {me.phoneNumber}
        </Typography>
      </CardContent>
    </Card>
  );
}

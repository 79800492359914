export const getToken = () => {
    const cookieName = 'apiResponse'; // Çerez adını buraya yazın
    const cookies = document.cookie.split(';'); // Tüm çerezleri ayır ve diziye dönüştür

    // Çerezleri döngüyle kontrol et
    for (const cookie of cookies) {
        const trimmedCookie = cookie.trim(); // Boşlukları temizle
        const [name, value] = trimmedCookie.split('='); // Çerezi isim ve değerine ayır
        if (name === cookieName) {
            return value.replace(/"/g, ''); // Çerez adı 'apiResponse' olanın değerini döndür
        }
    }

    return null; // Çerez bulunamazsa null döndür
};
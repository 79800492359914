import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from "react";
import { getAllUsers } from '../../redux/AuthSlice/authSlice';
import { Button } from '@mui/material';
import DetailMenu from './DetailMenu';

const DataTable = ({rows}) => {

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'fullName', headerName: 'Ad Soyad', width: 130 },
    { field: 'email', headerName: 'E mail', width: 130 },
    {
      field: 'phoneNumber',
      headerName: 'Telefon Numarası',
      width: 130,
    },
    { field: 'role', headerName: 'Rolü', width: 130 },
    {
      field: 'isActive',
      headerName: 'Staus',
      sortable: false,
      width: 130,
      renderCell: (params) => {
        return (
          <div className="" style={{width : 20, height : 20, borderRadius : "50%" , backgroundColor : params.row.isActive ? 'green' : 'red'}}></div>
        );
      },
    },
    { field: 'action', headerName: 'Aksiyon', width: 130,  renderCell: (params) => {
      return (
        <div className="">
            <DetailMenu params={params}/>
        </div>
      );
    },},
  ];
  


  const allUser = useSelector(state => state.user.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllUsers())

  }, [dispatch]);
  return (
    <div style={{ maxHeight: 400, width: '100%', marginTop : 10}}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  );
}

export default DataTable;
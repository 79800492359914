import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { deleteUser } from "../../redux/AuthSlice/authSlice";

export default function AlertDialog({user}) {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleDeleteUser= () => {
    dispatch(deleteUser(user._id));
    handleClose();
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  React.useEffect(()=>{
    console.log(user)
  },[])
  return (
    <React.Fragment>
      <Typography sx={{border : "none" ,'&:hover': {
        border : "none"
      }}} variant="outlined" onClick={handleClickOpen}>
        Kullanıcıyı Sil
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Vazgeç
          </Button>
          <Button onClick={() => handleDeleteUser()} autoFocus>
            Sil
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
